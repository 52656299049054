<template>
  <div style="position: relative; background-color: #e7e6e6">
    <Sidebar />
    <StoreImage :store_detail="detailStore" />
    <div class="ps-2">
      <HeaderPage />
    </div>
    <div
      style="background-color: white"
      v-if="storeDetail.news_content !== null"
    >
      <div v-if="storeDetail.hasOwnProperty('store_id')">
        <v-img
          :src="storeDetail.news_image_url"
          :lazy-src="storeDetail.news_image_url"
        ></v-img>

        <v-container style="padding: 4px; margin-top: 10px">
          <v-col>
            <v-row v-text="storeDetail.news_content"></v-row>
          </v-col>
        </v-container>
      </div>
      <div v-else>
        <Skeleton />
      </div>
      <v-container
        style="padding: 4px; margin-top: 10px"
        v-if="isLoadingMarketplaceGroup"
      >
        <SkeletonMarketplace />
      </v-container>
      <v-container v-else id="marketplace-container">
        <v-row
          justify="center"
          style="position: relative; z-index: 0"
          elevation-0
        >
          <v-expansion-panels accordion elevation-0 flat>
            <v-expansion-panel
              v-for="(item, i) in listMarketplaceGroup"
              :key="i"
              elevation-0
            >
              <v-expansion-panel-header
                expand-icon=""
                style="padding: 4px"
                :id="item.id"
              >
                <v-col
                  @click="
                    fetchMarketplaceList(item.id);
                    getIdGroupMarketplace(item.id);
                  "
                >
                  <v-row>
                    <v-col
                      class="font-weight-medium pb-0"
                      style="
                        font-size: 16px;
                        margin-bottom: 1em;
                        padding-right: 0px;
                        padding-bottom: 1em;
                        padding-left: 1px;
                      "
                    >
                      <div>
                        <p class="ps-3 text_title" style="line-height: 1.5em">
                          {{ item.marketplace_group_name }}
                        </p>
                      </div>
                    </v-col>
                  </v-row>
                  <v-row style="">
                    <v-img
                      :src="item.image_url"
                      class="rounded-lg banner-marketplace"
                      style="border: 2px solid #e7e6e6"
                    ></v-img>
                  </v-row>
                </v-col>
              </v-expansion-panel-header>
              <v-expansion-panel-content
                id="marketplace-content"
                style="
                  background-color: #e7e6e6;
                  height: auto;
                  padding-left: 10px;
                  padding-right: 10px;
                  /* padding-bottom: 7px; */
                "
              >
                <div v-if="isLoadingMarketplace" style="text-align: center">
                  <v-progress-circular
                    indeterminate
                    class="color"
                    style="margin-top: 20px"
                  ></v-progress-circular>
                </div>
                <div v-else>
                  <div v-if="listMarketplace.length > 0">
                    <div v-if="listMarketplace[0].hasOwnProperty('item_id')">
                      <ListItemMarketplace
                        style="margin-bottom: 3px"
                        :data_list="splitChunks(listMarketplace, 20)"
                        :is_loading="isLoadingItemMarketplace"
                        :group_marketplace_id="groupMarketplaceId"
                      />
                    </div>
                    <div v-else>
                      <div
                        id="marketplace-list-card"
                        @mousemove="scrollMarketplace()"
                      >
                        <div class="grid-card-marketplace">
                          <div
                            v-for="(itemMarket, i) in listMarketplace"
                            :key="i"
                          >
                            <v-card
                              elevation="0"
                              class="p-0 marketplace-list"
                              v-bind:class="{
                                isActiveMarketplace:
                                  itemMarket.marketplace_id === activeId,
                              }"
                              @click="
                                fetchItemMarketpalceList(
                                  itemMarket.marketplace_id
                                );
                                getIdMarketplace(itemMarket.marketplace_id);
                              "
                              v-on:click="changePadding()"
                              style="
                                padding-left: 5px;
                                padding-right: 5px;
                                padding-top: 5px;
                                overflow: hidden;
                                background-color: #dedddd;
                              "
                            >
                              <div style="position: relative">
                                <v-img
                                  class="marketplace-list-img rounded"
                                  :src="
                                    itemMarket.image_url || itemMarket.store_img
                                  "
                                  :lazy-src="
                                    itemMarket.image_url || itemMarket.store_img
                                  "
                                  style="border: 1px solid #dfdede"
                                ></v-img>
                              </div>

                              <div class="marketplace-list-text">
                                {{
                                  limitString(
                                    itemMarket.marketplace_name,
                                    20,
                                    true
                                  )
                                }}
                              </div>
                            </v-card>
                          </div>
                        </div>
                      </div>
                      <ListItemMarketplace
                        v-bind:style="{ 'margin-top': computedPadding }"
                        :data_list="listItemMarketplace"
                        :is_loading="isLoadingItemMarketplace"
                        :id="activeId"
                        :marketplace_id="marketplaceId"
                      />
                    </div>
                  </div>
                  <div
                    v-else
                    class="d-flex justify-center"
                    style="margin-top: 20px"
                  >
                    {{ $t("message.label-item-empty") }}
                  </div>
                </div>
              </v-expansion-panel-content>
            </v-expansion-panel>
          </v-expansion-panels>
        </v-row>
      </v-container>

      <v-list-item
        v-if="storeDetail.news_content !== null || listMarketplace.length > 0"
        class=""
      ></v-list-item>
    </div>
    <div style="margin-bottom: 7em">
      <div id="" v-if="isLogin == 1">
        <div v-if="listItemFavByStore.length > 0">
          <div class="row-category" style="">
            <ProductSlide
              :data_slide="listItemFavByStore"
              :title_slide="$t('message.favorites-products')"
              :link_slide="`/store/${this.idStore}/wishlist`"
              :is_loading="isLoadingFav"
              :data_store="[]"
            />
          </div>
        </div>

        <div v-if="listItemLastSeenByStore.length > 0">
          <div class="row-category" style="margin-top: 40px">
            <ProductSlide
              :data_slide="listItemLastSeenByStore"
              :title_slide="$t('message.last-seen-products')"
              :link_slide="`/store/${this.idStore}/recommendation`"
              :is_loading="isLoadingRec"
              :data_store="[]"
            />
          </div>
        </div>

        <div v-if="listItemOrderByStore.length > 0">
          <div class="row-category" style="margin-top: 40px">
            <ProductSlide
              :data_slide="listItemOrderByStore"
              :title_slide="$t('message.purchase-history-store')"
              :link_slide="`/store/${this.idStore}/purchase-history`"
              :is_loading="isLoadingOrder"
              :data_store="[]"
            />
          </div>
        </div>
      </div>
      <div style="margin-top: 3em">
        <v-card class="mx-auto justify-center text-center">
          <v-img tile height="200px" :src="detailStore.image_url"> </v-img>
        </v-card>

        <v-card class="pb-7" flat>
          <div class="pt-5 ps-3">
            <p class="ps-3 style-title">{{ $t("message.law-transaction") }}</p>
          </div>
        </v-card>

        <div class="px-3">
          <table
            class="table table-bordered border-primary"
            style="padding: 0px"
          >
            <tbody>
              <tr>
                <th>販売業者</th>
                <td>{{ listShopInfo.distributor }}</td>
              </tr>
              <tr>
                <th class="text-wrap">責任者</th>
                <td>{{ listShopInfo.pic }}</td>
              </tr>
              <tr>
                <th>所在地</th>
                <td>{{ listShopInfo.location }}</td>
              </tr>
              <tr>
                <th>問い合わせ先</th>
                <td>{{ listShopInfo.contact }}</td>
              </tr>
              <tr>
                <th>メールアドレス</th>
                <td>{{ listShopInfo.public_email }}</td>
              </tr>
              <tr>
                <th>販売価格</th>
                <td>{{ listShopInfo.seeling_price }}</td>
              </tr>
              <tr>
                <th>商品代金以外に必要な手数料</th>
                <td>
                  {{ listShopInfo.fee }}
                </td>
              </tr>
              <tr>
                <th>お支払い方法</th>
                <td>
                  {{ listShopInfo.payment_method }}
                </td>
              </tr>
              <tr>
                <th>商品の引渡し時期</th>
                <td>
                  {{ listShopInfo.delivery_time }}
                </td>
              </tr>
              <tr>
                <th>返品・キャンセルの対応</th>
                <td>{{ listShopInfo.correspondence }}</td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>

      <CategoryFooter />
      <p v-if="detailStore.info_medic_url" style="font-size: 12px" class="ps-3">
        {{ $t("message.label-shopInfoMedic") }}
        <span
          class="pink--text"
          style="cursor: pointer"
          @click.prevent="$router.push(`/store/${detailStore.id}/medic`)"
        >
          {{ $t("message.click") }}
        </span>
      </p>
      <Cashier />
      <div style="">
        <Sidebar />
      </div>
    </div>
  </div>
</template>

<script>
import User from "../../components/User.vue";
import LoadingPage from "../../components/LoadingPage.vue";
import Skeleton from "../../components/skeleton/Skeleton.vue";
import StoreImage from "../../components/StoreImage.vue";
import CategoryFooter from "../../components/CategoryFooter.vue";
import ProductSlide from "../../components/ProductSlide.vue";
import Sidebar from "../../components/developmentv2/Sidebar.vue";
import Cashier from "../../components/Cashier.vue";
import Product from "../../components/Product.vue";
import HeaderNavigasi from "../../components/HeaderNavigasi.vue";
import HeaderPage from "../../components/developmentv2/HeaderPage.vue";
import { mdiMagnify, mdiHeartOutline, mdiCart, mdiStore } from "@mdi/js";
import SkeletonMarketplace from "../../components/skeleton/SkeletonMarketplace.vue";
import ListItemMarketplace from "../../components/ListItemMarketplace.vue";

export default {
  name: "ShopDetail",
  data() {
    return {
      notFound: require("../../assets/image-not-found.png"),
      idStore: "",
      isLoading: false,
      isLoadingRec: false,
      isLoadingPickup: false,
      isLoadingOrder: false,
      isLoadingFav: false,
      isLoadingCategory: false,
      isLoadingItemMarketplace: false,
      isLoadingMarketplaceGroup: false,
      isLoadingMarketplace: false,
      pageRecom: 1,
      store_image: null,
      loadingStore: true,
      paddingTop: 0,
      activeId: 0,
      marketplaceId: 0,
      groupMarketplaceId: 0,
    };
  },
  components: {
    Sidebar,
    Cashier,
    HeaderNavigasi,
    ProductSlide,
    CategoryFooter,
    StoreImage,
    LoadingPage,
    Skeleton,
    Username: User,
    Product,
    HeaderPage,
    SkeletonMarketplace,
    ListItemMarketplace,
  },
  methods: {
    getIdMarketplace(id) {
      this.marketplaceId = id;
    },
    getIdGroupMarketplace(id) {
      this.groupMarketplaceId = id;
    },
    limitString: function (text, count, insertDots) {
      return (
        text.slice(0, count) + (text.length > count && insertDots ? "..." : "")
      );
    },
    handleIsLogin() {
      if ($cookies.get("isLogin") == 0 && localStorage.usr_tkn) {
        localStorage.removeItem("usr_tkn");
      }
      if ($cookies.get("isLogin") == 0) {
        localStorage.removeItem("email");
        localStorage.removeItem("token");
        localStorage.removeItem("user_register");
      }
    },
    shopInfo(shopInfo) {
      window.open(shopInfo);
    },
    shopInfoMedic(info_medic) {
      window.open(info_medic);
    },
    async storeDetailList() {
      //this.isLoadingStore = true;
      let id = this.$router.history.current.params.id;
      let data = await this.$store.dispatch("shop_module/fetchStoreDetail", {
        id: id,
      });
      //this.isLoadingStore = false;
      return data;
    },
    async marketplaceList(id) {
      this.isLoadingMarketplace = true;
      await this.$store.dispatch("shop_module/fetchMarketplace", {
        id: id,
      });
      this.isLoadingMarketplace = false;

      let element = document.getElementById(id);
      let headerOffset = 50;
      let elementPosition = element.getBoundingClientRect().top;
      let offsetPosition = elementPosition - headerOffset;

      window.scrollBy({
        top: offsetPosition,
        behavior: "auto",
      });
    },
    async itemMarketplaceList(id) {
      this.isLoadingItemMarketplace = true;
      await this.$store.dispatch("shop_module/fetchItemMarketplace", {
        id: id,
      });
      this.isLoadingItemMarketplace = false;
    },
    async marketplaceGroup() {
      this.isLoadingMarketplaceGroup = true;
      let id = this.$router.history.current.params.id;
      let data = await this.$store.dispatch(
        "shop_module/fetchMarketplaceGroup",
        {
          id: id,
        }
      );
      this.isLoadingMarketplaceGroup = false;
      return data;
    },
    fetchMarketplaceList(id) {
      this.activeId = 0;
      this.paddingTop = 0;
      this.clearStateMarketplace();
      this.clearStateItemMarketplace();
      this.marketplaceList(id);
    },
    fetchItemMarketpalceList(id) {
      this.activeId = id;
      this.itemMarketplaceList(id);
    },
    async itemLastSeenByStoreList() {
      //this.isLoadingItemLastSeenByStore = true;
      let id = this.$router.history.current.params.id;
      let data = await this.$store.dispatch(
        "itemLastSeen_module/itemLastSeenByStore",
        {
          id: id,
        }
      );
      //this.isLoadingItemLastSeenByStore = false;
      return data;
    },
    // async dispatchItemPickup() {
    //   this.isLoading = true;
    //   let id = this.$router.history.current.params.id;
    //   let data = await this.$store.dispatch(
    //     "shop_module/fetchItemPickupByStore",
    //     { id: id }
    //   );
    //   this.isLoading = false;
    //   return data;
    // },
    async dispatchItemPickupByStore() {
      this.isLoadingPickup = true;
      this.loadingStore = true;
      let id = this.$router.history.current.params.id;
      let data = await this.$store.dispatch(
        "shop_module/fetchItemListPickupByStore",
        { id: id }
      );
      this.isLoadingPickup = false;
      this.loadingStore = false;
      return data;
    },
    async dispatchStore() {
      this.loadingStore = true;
      this.idStore = this.$router.history.current.params.id;
      let id = this.$router.history.current.params.id;
      let data = await this.$store.dispatch("shop_module/fetchStore", {
        id: id,
      });
      let storeImage = await this.$store.state.shop_module.store
        .raw_header_images[0];
      if (storeImage == null) {
        this.store_image = null;
      } else {
        this.store_image = storeImage.image_url;
      }
      this.loadingStore = false;

      return data;
    },
    async dispatchShopInfo() {
      let id = this.$router.history.current.params.id;
      await this.$store.dispatch("shop_module/shopInfo", { shopId: id });
    },
    async itemRecomendByStore() {
      this.isLoadingRec = true;
      this.loadingStore = true;
      let id = this.$router.history.current.params.id;
      let data = await this.$store.dispatch(
        "itemRecomend_module/itemRecomendByStore",
        {
          id: id,
          page: this.pageRecom,
        }
      );
      this.isLoadingRec = false;
      this.loadingStore = false;
      return data;
    },
    async itemFavoriteByStore() {
      this.isLoadingFav = true;
      //this.loadingStore = true;
      let id = this.$router.history.current.params.id;
      let data = await this.$store.dispatch(
        "itemFavorite_module/itemFavoriteByStore",
        id
      );
      this.isLoadingFav = false;
      //this.loadingStore = false;
      return data;
    },
    async itemOrderByStore() {
      this.loadingStore = true;
      this.isLoadingOrder = true;
      let id = this.$router.history.current.params.id;
      let data = await this.$store.dispatch(
        "order_module/itemOrderByStore",
        id
      );
      this.isLoadingOrder = false;
      this.loadingStore = false;
      return data;
    },
    async listCategoryByStore() {
      this.isLoadingCategory = true;
      this.loadingStore = true;
      let id = this.$router.history.current.params.id;
      let data = await this.$store.dispatch(
        "shop_module/fetchCategoryItemShop",
        { id: id }
      );
      this.isLoadingCategory = false;
      this.loadingStore = false;
      return data;
    },
    detailCategory(slug) {
      let id_store = this.$router.history.current.params.id;
      this.$router.push(`/store/${id_store}/${slug}`);
    },
    splitChunks(sourceArray, chunkSize) {
      let result = [];
      for (var i = 0; i < sourceArray.length; i += chunkSize) {
        result[i / chunkSize] = sourceArray.slice(i, i + chunkSize);
      }

      return result;
    },
    changePadding() {
      this.paddingTop = "10px";
    },
    clearStateMarketplace() {
      this.$store.dispatch("shop_module/clearStateMarketplace");
    },
    clearStateItemMarketplace() {
      this.$store.dispatch("shop_module/clearStateItemMarketplace");
    },
    scrollMarketplace() {
      const slider = document.getElementById("marketplace-list-card");
      let isDown = false;
      let startX;
      let scrollLeft;

      slider.addEventListener("mousedown", (e) => {
        isDown = true;
        slider.classList.add("active");
        startX = e.pageX - slider.offsetLeft;
        scrollLeft = slider.scrollLeft;
      });
      slider.addEventListener("mouseleave", () => {
        isDown = false;
        slider.classList.remove("active");
      });
      slider.addEventListener("mouseup", () => {
        isDown = false;
        slider.classList.remove("active");
      });
      slider.addEventListener("mousemove", (e) => {
        if (!isDown) return;
        e.preventDefault();
        const x = e.pageX - slider.offsetLeft;
        const walk = (x - startX) * 2; //scroll-fast
        slider.scrollLeft = scrollLeft - walk;
      });
    },
  },
  computed: {
    isLogin() {
      return $cookies.get("isLogin");
    },
    listShopInfo() {
      return this.$store.state.shop_module.shopInfo;
    },
    storeDetail() {
      return this.$store.state.shop_module.storeDetail;
    },
    listMarketplace() {
      return this.$store.state.shop_module.marketplace;
    },
    listMarketplaceGroup() {
      return this.$store.state.shop_module.marketplaceGroup;
    },
    listItemMarketplace() {
      let res = this.splitChunks(
        this.$store.state.shop_module.itemMarketplace,
        20
      );

      return res;
    },
    listItemLastSeenByStore() {
      return this.$store.state.itemLastSeen_module.itemLastSeenByStore;
    },
    // listItemPickupByStore() {
    //   return this.$store.state.shop_module.itemPickupByStore;
    // },
    itemListPickupByStore() {
      return this.$store.state.shop_module.itemListPickupByStore;
    },
    detailStore() {
      return this.$store.state.shop_module.store;
    },
    listItemRecomendByStore() {
      return this.$store.state.itemRecomend_module.itemRecommendByStore;
    },
    listItemOrderByStore() {
      return this.$store.state.order_module.itemOrderByStore;
    },
    listItemFavByStore() {
      return this.$store.state.itemFavorite_module.itemFavByStore;
    },
    listCategory() {
      return this.$store.state.shop_module.categoryItemShop;
    },
    links() {
      return [
        {
          text: this.$t("message.route-search"),
          route: `/store/${this.idStore}/category`,
          icon: mdiMagnify,
        },
        {
          text: this.$t("message.route-favorite"),
          route: `/store/${this.idStore}/wishlist`,
          icon: mdiHeartOutline,
        },
        {
          text: this.$t("message.route-history"),
          route: `/store/${this.idStore}/purchase-history`,
          icon: mdiCart,
        },
        {
          text: this.$t("message.route-store"),
          route: "/store",
          icon: mdiStore,
        },
      ];
    },
    computedPadding: function () {
      return this.paddingTop;
    },
  },
  created() {
    //this.listCategoryByStore();
    this.itemFavoriteByStore();
    //this.itemRecomendByStore();
    this.itemOrderByStore();
    this.dispatchStore();
    this.dispatchShopInfo();
    //this.dispatchItemPickup();
    //this.dispatchItemPickupByStore();
    this.storeDetailList();
    //this.marketplaceList();
    this.marketplaceGroup();
    this.itemLastSeenByStoreList();
    this.handleIsLogin();
  },
};
</script>

<style scoped>
.style-title-shop_category {
  text-align: center;
  line-height: 1;
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 4;
  -webkit-box-orient: vertical;
}
.class-category {
  margin-top: 20px;
  background-color: #e0e0e0;
  width: 100%;
  height: auto;
}
.card-category {
  width: 135px;
  height: 12vh;
  justify-content: center;
  margin: 3px;
}
.card-item {
  justify-content: center;
}

.style-title {
  /* text-transform: capitalize; */
  border-left: 10px solid #ff0090;
  font-size: 16px;
  color: #424242;
}
table {
  min-width: 95%;
  margin: auto;
  border: 1px solid #707070;
}
td {
  min-width: 40%;
  flex: 1;
}
tr {
  display: flex;
  flex-wrap: wrap;
}
tr + tr {
  border-top: 2px solid #707070;
  border-color: #707070;

  padding: 0px !important;
}
tr th {
  background-color: #e0e0e0;
  width: 33%;
  text-align: left;
  padding: 5px;
  /* text-overflow: w; */
}
tr td {
  padding: 5px !important;
}

.text_title {
  /* text-transform: capitalize; */
  border-left: 10px solid #ff0090;
  font-size: 16px;
  color: #424242;
}

.row-scroll::-webkit-scrollbar {
  display: none;
}

#marketplace-content::v-deep(.v-expansion-panel-content__wrap) {
  padding-left: 0 !important;
  padding-right: 0 !important;
  margin-top: 13px !important;
  margin-bottom: 13px !important;
}

.isActiveMarketplace {
  background-color: #ffffff !important;
  border: 2px solid #9b9b9b !important;
}

.grid-card-marketplace {
  display: grid;
  grid-auto-flow: column;
  grid-gap: 0.3em;
}

::-webkit-scrollbar {
  display: none;
}

* {
  scrollbar-width: none; /* Firefox implementation */
}

#marketplace-list-card {
  overflow-y: hidden;
  overflow-x: auto;
}

@media only screen and (min-width: 600px) and (max-width: 960px) {
  .banner-marketplace {
    max-height: 100px;
  }

  .grid-card-marketplace {
    width: 150px;
  }

  .marketplace-list {
    max-width: 150px;
    max-height: 150px;
  }

  .marketplace-list-img {
    width: 290px;
    height: 110px;
  }

  .marketplace-list-text {
    padding: 3px 0 3px 0;
    font-size: 0.8em;
    height: 30px;
    display: flex;
    justify-content: center;
    align-items: center;
  }
}

@media only screen and (max-width: 599px) {
  .banner-marketplace {
    max-height: 100px;
  }

  .grid-card-marketplace {
    width: 110px;
  }

  .marketplace-list {
    max-width: 110px;
    max-height: 110px;
  }

  .marketplace-list-img {
    width: 287px;
    height: 70px;
  }

  .marketplace-list-text {
    font-size: 0.7em;
    height: 30px;
    line-height: 1.1;
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;
  }
}

@media only screen and (min-width: 961px) {
  .banner-marketplace {
    max-height: 200px;
  }

  .grid-card-marketplace {
    width: 200px;
  }

  .marketplace-list {
    max-width: 200px;
    max-height: 200px;
  }

  .marketplace-list-img {
    width: 300px;
    height: 140px;
  }

  .marketplace-list-text {
    height: 50px;
    display: flex;
    justify-content: center;
    align-items: center;
  }
}
</style>
