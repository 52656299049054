<template>
  <div>
    <div v-if="is_loading" class="d-flex justify-center">
      <v-progress-circular indeterminate class="color"></v-progress-circular>
    </div>
    <div id="marketplace-items" v-else @mousemove="scrollItem()">
      <div v-if="data_list.length > 0">
        <div v-for="(list, idx) in data_list" :key="idx">
          <div class="grid-card">
            <div v-for="(item, i) in filterItemResult(list)" :key="i">
              <div v-if="item.length === 0"></div>
              <div v-else>
                <div v-if="item.item_id == null">
                  <v-card
                    elevation="0"
                    style="
                      border: 1px solid black;
                      background-color: #ece8e4 !important;
                      width: 120px;
                      height: 283px;
                    "
                    class="d-flex justify-center items-width-empty"
                  >
                  </v-card>
                </div>
                <div v-else>
                  <Product
                    :data="item"
                    @data-slug="getModal"
                    :marketplace_id="marketplace_id"
                    :group_marketplace_id="group_marketplace_id"
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div v-else class="d-flex justify-center">
        <div v-if="id !== 0" style="margin-bottom: 10px">
          {{ $t("message.label-item-empty") }}
        </div>
      </div>
    </div>
    <DetailModal
      :item_slug="slug"
      :marketplace_id="marketplace_id"
      :group_marketplace_id="group_marketplace_id"
      :showDialog="dialog"
      @get-modal="handleModal"
      @dialogVisible="dialog"
    />

    <ConfirmationProduct
      :data_product="data_dialog_confirm"
      :showDialog="dialog_confirmation_product"
      @close="getCloseModal"
      @dialogVisible="dialog_confirmation_product"
    />
  </div>
</template>
<script>
import ConfirmationProduct from "./ConfirmationProduct.vue";
import DetailModal from "../components/DetailModal.vue";
import Product from "../components/ProductAlt.vue";
import { mdiBlockHelper as icon_empty } from "@mdi/js";
export default {
  name: "ListItemMarketplace",
  data() {
    return {
      slug: "",
      dialog: false,
      dialog_confirmation_product: false,
      data_dialog_confirm: {},
      data_dialog_warning: null,
      dialog_warning: false,
    };
  },
  components: {
    DetailModal,
    Product,
    ConfirmationProduct,
  },
  props: {
    data_list: Array,
    is_loading: Boolean,
    id: Number,
    marketplace_id: Number,
    group_marketplace_id: Number,
  },
  methods: {
    getCloseModal() {
      this.dialog = false;
      this.dialog_confirmation_product = false;
    },
    handleModal(data) {
      if (data[0] == "ConfirmProduct") {
        this.data_dialog_confirm = data[1];
        this.dialog_confirmation_product = true;
      } else {
        this.dialog = false;
      }
    },
    getModal(data) {
      if (typeof data === "string") {
        this.slug = data;
        this.dialog = true;
      } else {
        this.data_dialog_confirm = data;
        this.dialog_confirmation_product = true;
      }
    },
    filterItemResult(arr) {
      let i = 0;
      let j = 0;
      do {
        if (arr[i].item_id == null) {
          j += 1;
        }
        i++;
      } while (i < arr.length);

      if (j == 20) {
        return [];
      } else {
        for (let i = arr.length - 1; i >= 0; i--) {
          if (arr[i].item_id == null) {
            if (
              arr[i].item_id == arr[i - 1].item_id ||
              (arr[i].item_id == null && arr[i - 1].item_id != null)
            ) {
              arr.pop();
            }
          } else {
            break;
          }
        }

        return arr;
      }
    },
    scrollItem() {
      const slider = document.getElementById("marketplace-items");
      let isDown = false;
      let startX;
      let scrollLeft;

      slider.addEventListener("mousedown", (e) => {
        isDown = true;
        slider.classList.add("active");
        startX = e.pageX - slider.offsetLeft;
        scrollLeft = slider.scrollLeft;
      });
      slider.addEventListener("mouseleave", () => {
        isDown = false;
        slider.classList.remove("active");
      });
      slider.addEventListener("mouseup", () => {
        isDown = false;
        slider.classList.remove("active");
      });
      slider.addEventListener("mousemove", (e) => {
        if (!isDown) return;
        e.preventDefault();
        const x = e.pageX - slider.offsetLeft;
        const walk = (x - startX) * 2; //scroll-fast
        slider.scrollLeft = scrollLeft - walk;
      });
    },
  },
};
</script>
<style>
.grid-card {
  display: grid;
  grid-template-columns: repeat(20, 1fr);
  grid-gap: 0.3em;
  width: 120px;
  min-height: 290px;
  margin-bottom: 0.1em;
}

.color {
  color: #ff0090;
}

#marketplace-items {
  overflow-y: hidden;
  overflow-x: auto;
  cursor: grab;
  transition: all 0.2s;
}

#marketplace-items.active {
  cursor: grabbing;
  cursor: -webkit-grabbing;
  transform: scale(1);
}
</style>
