<template>
  <div class="p-0">
    <!-- <div class="d-flex align-center">
      <Warning :openDialog="dialog" @handle-dialog="handleDialog" />
    </div> -->

    <Skeleton v-if="loading" />
    <div v-else>
      <v-card
        class="p-0 items-width"
        elevation="0"
        style="border: 1px solid black"
      >
        <div @click="(e) => getModal(e, data.slug)" style="cursor: pointer">
          <v-img
            height="120"
            width="100%"
            alt=""
            :lazy-src="data.image_url"
            :src="data.image_url"
          ></v-img>
          <v-card-text id="product-card">
            <div class="item-text-header-alt">{{ data.item_name }}</div>
          </v-card-text>
        </div>
        <div class="desc-item">
          <v-icon
            @click="addFavorite(data)"
            dark
            :color="isFavorite()"
            style="position: absolute; top: 65%; left: 0; padding: 5px"
          >
            mdi-heart
          </v-icon>
          <div class="d-flex flex-column pt-4">
            <v-row class="d-flex justify-end pr-4 items-content-font-size">
              <span style="color: #ff0090">
                <span style="font-size: 0.7em; vertical-align: baseline">
                  {{ $t("message.without-tax") }}
                </span>
                <span style="font-size: 0.7em">
                  {{ formatDecWithoutComa(data.price_without_tax) }}
                </span>
                <span style="font-size: 0.7em; vertical-align: baseline">
                  円</span
                >
              </span>
            </v-row>
            <v-row class="d-flex justify-end pr-4">
              <div
                v-if="!conditionalPrice(data.price)"
                class=""
                style="font-size: 0.7em"
              >
                <span>
                  {{ $t("message.with-tax") }}
                </span>
                <span style="font-size: 1em">
                  {{ formatDecWithoutComa(data.price) }} 円
                </span>
              </div>
              <div v-else class="" style="">
                <span style="font-size: 0.7em">
                  {{ $t("message.with-tax") }}
                </span>
                <!-- NOTE: CONDITIONAL STYLE NUMBER DIGIT PRICE MORE THAN EQUALS 6 -->
                <span
                  :style="
                    numDigitPrice(data.price) >= 6
                      ? 'font-size: 0.7em;'
                      : 'font-size: 0.7em'
                  "
                >
                  {{ priceBeforeComa(data.price) }}
                </span>
                <span style="font-size: 0.6em; margin-left: -2px">
                  {{ priceAfterComa(data.price) }}
                </span>
                <span style="font-size: 0.7em">円</span>
              </div>
            </v-row>
            <v-row class="d-flex justify-end pr-4" style="font-size: 0.7em">
              {{ limitString(data.store_name, 17, true) }}
            </v-row>
          </div>
          <div
            id="btn-cart"
            class="d-flex justify-center align-center"
            style="
              z-index: 5;
              position: absolute;
              bottom: 0;
              margin-bottom: 1px;
              width: 98%;
            "
          >
            <v-btn
              class="add-cart-width"
              depressed
              small
              :disabled="
                data.is_stock == 0 ||
                data.item_status < 0 ||
                data.stock == 0 ||
                data.stock_total == 0
              "
              block
              style="padding: 8px"
              color="#ff0090"
              @click="(e) => addCart(e, data)"
            >
              <v-icon
                v-if="data.stock !== 0"
                class="pe-1"
                color="white"
                size="20"
                small
              >
                mdi-cart-outline
              </v-icon>
              <div v-if="data.stock !== 0" style="color: white">
                {{ $t("message.btn-add-cart") }}
              </div>
              <div v-else-if="data.stock == 0" style="color: white">
                売り切れ
              </div>
            </v-btn>
          </div>
        </div>
      </v-card>
    </div>
  </div>
</template>

<script>
import moneyFormat from "../helpers/moneyFormat";
import Skeleton from "@/components/skeleton/Skeleton";
import Swal from "sweetalert2";
import formatDecWithoutComa from "../helpers/formatDecWithoutComa";
import priceBeforeComa from "../helpers/priceBeforeComa";
import priceAfterComa from "../helpers/priceAfterComa";
import Warning from "../components/Warning.vue";

export default {
  name: "Product",
  data() {
    return {
      notFound: require("../assets/image-not-found.png"),
      message: {},
      itemId: "",
      storeId: "",
      dialog: false,
    };
  },
  components: {
    Skeleton,
    Warning,
  },

  props: {
    data: Object,
    loading: Boolean,
    marketplace_id: Number,
    group_marketplace_id: Number,
  },
  mounted() {},
  methods: {
    getModal(e, data) {
      if (e == "ConfirmProduct") {
        this.$emit("data-slug", data);
        this.$emit("dialog", true);
      } else {
        this.$emit("data-slug", data);
        this.$emit("dialog", true);
      }
    },
    handleDialog(val) {
      if (val.selected == "yes") {
        this.message.title = this.$t("message.title-add-carts");
        this.$store.dispatch("carts_module/addCarts", {
          itemId: this.itemId,
          storeId: this.storeId,
          message: {
            title: this.message.title,
          },
        });
      }
      this.dialog = false;
    },
    moneyFormat(money) {
      return moneyFormat(money);
    },
    // NOTE: FUNCTION CONDITIONAL HANDLE STYLE NUMBER DIGIT PRICE
    numDigitPrice(price) {
      let convertStr = String(price);
      let cekComa = convertStr.indexOf(".");
      return cekComa;
    },
    // NOTE: FUNCTION CONDITIONAL HANDLE PRICE WITHOUT COMA & WITH COMA
    conditionalPrice(price) {
      let strPrice = String(price);
      let flag = false;
      for (let i = 0; i < strPrice.length; i++) {
        if (strPrice[i] === ".") {
          flag = true;
          break;
        }
      }
      if (flag) {
        return true;
      } else {
        return false;
      }
    },
    priceBeforeComa(price) {
      return priceBeforeComa(String(price));
    },
    priceAfterComa(price) {
      return priceAfterComa(String(price));
    },
    formatDecWithoutComa(money) {
      return formatDecWithoutComa(money);
    },
    addCart(e, data) {
      localStorage.setItem("notes", "");
      let nameEvent;
      if (e.isTrusted) {
        nameEvent = "ConfirmProduct";
      }
      if (data.is_rapid_antigen_tools == 1) {
        this.getModal(nameEvent, data);
      } else {
        this.itemId = data.item_id;
        this.storeId = data.store_id;
        e.stopPropagation();

        this.$gtag.event("add-cart-click", {
          event_category: "Enhanced Ecommerce",
          event_label: data.item_name,
          value: data.price,
        });

        let cookies = $cookies.get("isLogin");
        if (cookies == 0) {
          Swal.fire({
            icon: "error",
            text: this.$t("message.title-err-login-first"),
          });
        } else {
          if (data.is_alcohol_item == 1) {
            this.dialog = true;
          } else {
            this.message.title = this.$t("message.title-add-carts");
            this.$store.dispatch("carts_module/addCarts", {
              storeId: data.store_id,
              itemId: data.item_id,
              message: {
                title: this.message.title,
              },
            });
          }
        }
      }
    },
    toDetail(data, slug) {
      if (data.item_status < 0) {
        return 0;
      } else {
        this.$gtag.event("quickview-click", {
          event_category: "Enhanced Ecommerce",
          event_label: data.item_name,
          value: data.price,
        });
        this.$router.push("/items/" + slug);
      }
    },
    addFavorite: function (data) {
      let cookies = $cookies.get("isLogin");
      if (cookies == 0) {
        Swal.fire({
          icon: "error",
          title: "Oops...",
          text: this.$t("message.title-err-login-first"),
        });
      } else {
        this.$gtag.event("wishlist-item-click", {
          event_category: "Enhanced Ecommerce",
          event_label: data.item_name,
          value: data.price,
        });
        // let search = this.$route.query.search;
        // let itemId = data.item_id;
        // let slug = data.slug;
        // let marketplaceId = this.marketplace_id;
        // let groupMarketplaceId = this.group_marketplace_id;

        // let payload = {
        //   item_id: itemId,
        //   slug: slug,
        //   search: search,
        //   marketplaceId: marketplaceId,
        //   groupMarketplaceId: groupMarketplaceId,
        // };

        let payload = {
          item_id: data.item_id,
          marketplaceId: this.marketplace_id,
          groupMarketplaceId: this.group_marketplace_id,
          page: "marketplace",
        };

        this.$store.dispatch("itemFavorite_module/addItemFavorite", payload);
      }
    },
    isFavorite() {
      if (this.data.has_favourites === 1) {
        return "#ff0090";
      } else {
        return "grey";
      }
    },
    limitString: function (text, count, insertDots) {
      if (text) {
        return (
          text.slice(0, count) +
          (text.length > count && insertDots ? "..." : "")
        );
      }
    },
  },
};
</script>
<style>
.items-width-empty {
  width: 120px !important;
}
.items-width {
  width: 120px !important;
}
.items-content-font-size {
  font-size: 1rem !important;
}
.toko {
  text-align: end;
  margin-right: 5px;
  margin-top: -10px;
}
.tax {
  text-align: end;
  margin-right: 5px;
}
.text_color {
  color: #ff0090;
  font-weight: 800;
}
.text_align {
  text-align: right;
}
.odd_even:nth-child(odd) {
  background-color: #eeeeee;
}
.odd_even:nth-child(even) {
  background-color: white;
}
.item-text {
  color: #263238;
  margin-bottom: 3em;
  font-family: roboto;
  font-size: 14px;
  line-height: 20px;
  font-weight: 400;
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
}
.item-text-header-alt {
  color: #263238;
  font-family: roboto;
  /*font-size: 16px;*/
  font-size: 0.8em;
  line-height: 1.3;

  font-weight: 500;
  overflow: hidden;
  text-overflow: ellipsis;
  height: 4em;
}
#product-card {
  padding: 8px !important;
}
.desc-item {
  /* background-color: #C8C2BC; */
  background-color: #ece8e4;
  /* height: 125px; */
  /*height: 100px; */
}
.class-btn-cart {
  /* position: absolute;
  bottom: 0%;
  width: 180px; */
  /* z-index: 5;
  display: flex;
  justify-content: center;
  align-content: center; */
}

/* @media only screen and (max-width: 440px) {
  .class-btn-cart {
    position: absolute;
    bottom: 0%;
    width: auto;
  }
}
@media only screen and (min-width: 1024px) {
  .class-btn-cart {
    position: absolute;
    bottom: 0%;
    width: 180px
  }
} */
@media only screen and (min-width: 600px) and (max-width: 960px) {
  #btn-cart {
    margin-left: 0.1em;
  }
}

@media only screen and (max-width: 599px) {
  #btn-cart {
    margin-left: 0.1em;
  }

  .items-width-empty {
    width: 110px !important;
  }
  .items-width {
    width: 110px !important;
  }
  .add-cart-width {
    width: 90%;
  }

  .items-content-font-size {
    font-size: 0.9rem !important;
  }
}

@media only screen and (min-width: 341px) and (max-width: 360px) {
  .items-width-empty {
    width: 100px !important;
  }
  .items-width {
    width: 100px !important;
  }
  .add-cart-width {
    width: 85%;
  }
  #btn-cart .v-btn__content {
    font-size: 0.6rem !important;
  }

  .items-content-font-size {
    font-size: 0.8rem !important;
  }
}

@media only screen and (min-width: 760px) and (max-width: 780px) {
  .items-width-empty {
    width: 100px !important;
  }
  .items-width {
    width: 100px !important;
  }
  .add-cart-width {
    width: 88%;
  }

  #btn-cart .v-btn__content {
    font-size: 0.6rem !important;
  }

  .items-content-font-size {
    font-size: 0.8rem !important;
  }
}

@media only screen and (min-width: 961px) {
  #btn-cart {
    margin-left: 0.06em;
  }
}

/* @media only screen and (max-width: 960px) {
  .top-banner {
    margin-top: 5em;
  }
} */
</style>
